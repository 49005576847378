import { BodyBig } from 'components/Typography';
import styled from 'styled-components';
import { getColor, mediaQuery, mediaScreen } from '../../utils/themeUtils';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: rgba(20, 21, 22, 0.5);
  z-index: ${({ theme }) => theme.zindex.menu};
  display: none;

  &.active {
    display: flex;
  }

  ${mediaScreen('sm')} {
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export const MobileTopLine = styled.div`
  display: none;

  ${mediaScreen('sm')} {
    display: block;
    width: 80px;
    height: 6px;
    border-radius: 100px;
    background: ${getColor(({ elements }) => elements.gray)};
    margin: 0 auto 24px auto;
  }
`;

export const ModalContent = styled.div<{ floatWidth?: boolean }>`
  position: relative;
  max-width: ${({ floatWidth }) => (floatWidth ? 'none' : '624px')};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.bg.white};
  border-radius: 32px;
  padding: 56px 90px 96px 90px;

  ${mediaScreen('sm')} {
    padding: 14px 20px 40px 22px;
    border-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100vw;
    min-width: 100vw;
  }
`;

export const ModalTitle = styled.p`
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.font.blue};
  margin-bottom: 32px;

  ${mediaScreen('sm')} {
    margin: auto;
    font-size: 26px;
  }
`;

export const ModalClose = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  position: absolute;
  right: 20px;
  top: 20px;

  svg {
    width: 16px;
    height: 16px;
  }

  ${mediaScreen('sm')} {
    display: none;
  }
`;

export const ModalDescription = styled(BodyBig)``;

export const ModalActions = styled.div`
  display: flex;
  margin-top: 48px;

  ${mediaQuery('sm')} {
    flex-direction: column;
  }

  & > :not(:last-of-type) {
    ${mediaQuery('lg')} {
      margin-right: 32px;
    }

    ${mediaQuery('md')} {
      margin-right: 32px;
    }

    ${mediaQuery('sm')} {
      margin-bottom: 32px;
    }
  }

  & > button {
    ${mediaQuery('sm')} {
      width: 100%;
      max-width: 100%;
    }
  }
`;
